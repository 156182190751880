<template>
    <div class="page">
        <div class="w788" style="position: relative;">
            <topHeader @goBack="sysgoback()"></topHeader>
            <!-- 内容 -->
            <div class="home_conbox">
            	<div class="info-panel">
            		<div class="top-col">
            			<img class="head-img" :src="userInfo.hlImage || require('@/assets/logo.png')"></img>
            			<div class="info-right">
            				<div class="user-name">{{userInfo.nikename||'' }}</div>
            				<div class="sec-text" v-if="userInfo.level==0">你还不是会员</div>
            				<div class="sec-text" v-if="userInfo.level>0">{{vipTpyes[userInfo.level]}}</div>
            			</div>
            		</div>
            		<div class="show-tip">· 加入会员畅想学习乐趣 ·</div>
            	</div>
                        
            	<div class="vip-group" v-if="vipgoods.length>0">
            		<div class="vip-item" :class="{'vip-item-checked': index==checkedindex}" @click="changeSelect(index)"
            			v-for="(item,index) in vipgoods" :key="index">
            			<img src="../assets/static/youhui.png" class="yh-img" mode=""></img>
            			<div class="vip-label">{{item.goodsName}}</div>
            			<div class="price-text">￥{{item.goodsPrice}}</div>
            			<div class="origin-price">原价：￥{{item.goodsOriPrice}}</div>
            		</div>
            	</div>
                        
            	<div class="desc-content" v-if="vipgoods.length>0">
            		<div class="desc-title">会员权益</div>
            		<div class="desc-item" v-html="vipgoods[checkedindex].power"></div>
            	</div>
                        
            	<div class="desc-content" v-if="vipgoods.length>0">
            		<div class="desc-title">购买须知</div>
            		<div class="desc-item" v-html="vipgoods[checkedindex].buyKnow"></div>
            	</div>
            </div>
            <div class="bottom-group" v-if="vipgoods.length>0">
            	<div class="first-col">
					<el-checkbox class="radio" v-model="isagree"></el-checkbox>
            		<span class="pointer" @click="handleSearche('agreement')">选择同意《河狸英语会员服务协议》</span>
            	</div>
            	<div class="sub-btn" v-if="vipgoods[checkedindex].showbuy" @click="handleOrder">{{userInfo.level==0?'开通会员':'升级会员'}}</div>
				<div class="sub-btn opacity_btn" v-if="!vipgoods[checkedindex].showbuy">已升级</div>
            </div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 68px;" v-if="vipgoods.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
			
        </div>
		<div class="block30"></div>
		<!-- 支付页面 -->
		<doPay ref="refdoPay" :isShow="isShowPay" :payType="payType" :min-price="goodsPrice" @clickpayclose="clickpayclose" />
    </div>
</template>

<script>
import topHeader from '@/components/topHeader/topHeader.vue';
import { mapState,mapMutations } from 'vuex'
import {
    showLoading,
    hideLoading
} from '@/common/loading.js';
import {vipTpyes} from '@/common/constant.js'
import doPay from '@/components/doPay/doPay.vue';
export default {
    components: {
        topHeader,
		doPay
    },
    data() {
        return {
			vipTpyes,
			vipgoods: [],
			checkedindex:0,//下标
			isagree:true,
			payType: 0, //下标 0是微信
			isShowPay: false, // 支付弹窗
			goodsPrice:0,
        };
    },
    mounted: function () {
        // console.log(this.userInfo)
		//商品套餐
		this.getbooksgoods()
    },
    computed: {
        ...mapState(['userInfo', 'isLogin']),
    },
    methods: {
		...mapMutations(["SET_Data"]),
		//调整
		handleSearche(name){
			this.$router.push({
				name,
			});
		},
		changeSelect(index) {
			this.checkedindex = index
		},
		//支付
		handleOrder(){
			var _this = this
			if(!this.isagree){
				this.$message.error("请勾选同意协议")
				return false;
			}
			var params = {
				goodsId: this.vipgoods[this.checkedindex].id,
				payType:0,//支付类型 0微信 1支付宝
				userUuid:this.userInfo.uuid
			}
			showLoading();
			this.$http.post("pay/books-orders/CreateOrders", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					//继续支付
					_this.dopay(res)
				} else {
					hideLoading();
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		// 发动支付
		dopay(res) {
			var _this = this
			this.goodsPrice = this.vipgoods[this.checkedindex].goodsPrice
			this.isShowPay = true
			setTimeout(function() {
				_this.$refs.refdoPay.creatQrCode(res.data.payMsg)
			}, 800)
			hideLoading();
		},
		// 取消支付
		clickpayclose() {
			var _this = this
			this.$confirm('此操作将关闭支付弹窗，请确定已经支付成功，关闭后，将会跳转回课程详情页面，是否继续?', '提示', {
					confirmButtonText: '确定支付成功',
					cancelButtonText: '已取消支付',
					type: 'warning'
				}).then(() => {
					_this.isShowPay = false
					//普通订单
					_this.$router.push({
						path: '/myorder'
					})
				})
				.catch(() => {
					_this.isShowPay = false
					_this.$message({
						type: 'info',
						message: '已取消'
					})
				})
		},
		//商品套餐
		getbooksgoods() {
			var _this = this
			var params = {
				currentPage: 1,
				pageSize: 88
			}
			this.$http.post('app/word/orders/books-goods/getPage', params).then(function(res) {		
				if (res.code === 200) {
					var records = res.data.records
					var vipgoods = []
					records.forEach((item, index) => {
						if(item.userLevel>_this.userInfo.level){
							item["showbuy"] = true
						}else{
							item["showbuy"] = false
						}
						vipgoods.push(item)
					});
					_this.vipgoods = vipgoods
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//退款
		handletuikuan(index){
			this.$alert('您好，请在首页添加客服进行退款！', '提示', {
			    confirmButtonText: '确定',
			    callback: action => {}
			});
		},
		//刷新用户信息
		refreshUser(){
			var _this = this
			var params = {
				id: this.userInfo.id
			}
			this.$http.post('app/hlexam/hlUser/detail', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.SET_Data({ key: 'userInfo', value:res.data})
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},

    }
};
</script>
<style lang="scss" scoped>
    .page {
		width: 100%;
		position: relative;
	}
	.bottom-group {
		width: 358px;
		height: 158px;
		display: flex;
		align-items: center;
		flex-direction: column;
		.first-col {
			margin-top: 26px;
			color:#603C0A;
			font-size: 16px;
			display: flex;
			align-items: center;
			.radio {
				transform: scale(1.1);
				margin-bottom: 0px;
				padding-right: 8px;
			}
		}

		.sub-btn {
			height: 56px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 96px;
			background: #FEC87D;
			color:#603C0A;
			font-weight: bold;
			font-size: 16px;
			margin-top: 18px;
			cursor: pointer;
		}
	}

	.home_conbox {
		width: 100%;
		position: relative;
		z-index: 2;
		box-sizing: border-box;
		.info-panel {
			background: linear-gradient(127.28deg, #FFE2BD 112.93%, #FAF1E6 49.9%);
			margin-top: 18px;
			border-radius: 16px;
			box-sizing: border-box;
			padding: 16px 18px;
			display: flex;
			flex-direction: column;

			.top-col {
				display: flex;
				align-items: center;
			}

			.head-img {
				height: 60px;
				width: 60px;
				border-radius: 60px;
				border: 2px solid #FFFFFF
			}

			.info-right {
				margin-left: 12px;
			}

			.user-name {
				font-weight: bold;
				font-size: 16px;
				color: #603C0A;
			}

			.sec-text {
				margin-top: 3px;
				font-size: 14px;
				color: #5D3805;
			}

			.show-tip {
				font-size: 14px;
				color: #5D3805;
				width: 100%;
				display: flex;
				justify-content: center;
				letter-spacing: 1px;
				opacity: 0.5;
			}

		}

		.vip-group {
			display: flex;
			flex-wrap: wrap;
			margin: 8px;
			.vip-item-checked {
				border: 2px solid #FF6C19 !important;
				box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.22) !important;
				background: #fff !important;
			}

			.vip-item {
				margin: 8px 0 8px 8px;
				border: 2px solid #FED8A7;
				display: flex;
				align-items: center;
				flex-direction: column;
				border-radius: 8px;
				width: 108px;
				height: 125px;
				background: #FAF7ED;
				position: relative;
				box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.12);
				cursor: pointer;
				.vip-label {
					color: #603C0A;
					margin-top: 25px;
					font-size: 14px;
				}
				.price-text {
					color: #603C0A;
					margin-top: 8px;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 2px;
				}

				.origin-price {
					color: #603C0A;
					margin-top: 8px;
					font-size: 14px;
					text-decoration: line-through;
					opacity: 0.5;
				}

				.yh-img {
					width: 60px;
					height: 20px;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 2;
				}
			}
		}

		.desc-content {
			margin: 0 16px;

			&:last-child {
				margin-top: 16px;
			}
			.desc-title {
				font-size: 16px;
				font-weight: bold;
				border-left: solid 3px #51D9A5;
				color: #5C3704;
				padding-left: 8px;
				margin-bottom: 12px
			}

			.desc-item {
				margin-top: 5px;
				color: #333333;
				font-size: 14px;
				white-space:pre;
			}
		}
	}
</style>
